import { Fade } from "react-reveal";

const YearsOfWork = () => {
  return (
    <section id="home-technologies" className="years-of-working">
      <div className="main">
        <div className="case-container">
          <div className="sec-padding">
            <div className="main-portfolio">
              <div className="row">
                <div className="col-md-12 d-flex flex-column">
                  <Fade bottom cascade>
                    <div className="heading">
                      <h1>
                        We are the App Makers! Our expert mobile app developers
                        strive to provide you with the best user experience, and
                        custom android and iOS apps.
                      </h1>

                      <span className="underline"></span>
                    </div>

                    <div className="button">
                      <button className="custom-btn">Get Started</button>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default YearsOfWork;
