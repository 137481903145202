import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import imageUrl from "../utils/ImageUrl";
import { Bounce } from "react-reveal";
import { MobileData, frontendData, dataBaseData } from "../dummyData";
import { useEffect, useRef } from "react";
import { allData } from "./portfolio-data";
import { ListItemSecondaryAction } from "@material-ui/core";
import { Button } from "react-bootstrap";
function HomeTechnologies() {
  const navigate = useNavigate();

  const [firstData, setData] = useState([]);
  const [secondData, setSecondData] = useState([]);
  const [isMore, setIsMore] = useState(false);

  const handleNavigate = (link) => navigate(link);

  const [active, setActive] = useState(0);
  const handleData = (data) => {
    setData(data.firstRow);
    setSecondData(data.secondRow);
  };
  const handleActive = (value) => {
    // console.log(value);
    setActive(value);
  };

  const handleClick = (value, data) => {
    handleActive(value);
    handleData(data);
    console.log(value, data);
  };
  useEffect(() => {
    setData(MobileData.firstRow);
    setSecondData(MobileData.secondRow);
  }, []);

  return (
    <section id="home-technologies" className="home-portfolio">
      <div className="main wow fadeDown">
        <div className="container">
          <div className="sec-padding">
            <div className="main-portfolio">
              <div className="row">
                <div className="col-lg-3 col-md-12 d-flex flex-column">
                  <div className="tabbing-link">
                    <div className="heading">
                      <h1 className="text-lg-start text-sm-center text-center">
                        Our
                        <br /> Featured <br />
                        Portfolio
                      </h1>
                      <span className="underline"></span>
                    </div>
                    <div className="links">
                      <ul>
                        <li
                          className={`${active === 0 ? "active" : ""}`}
                          onClick={(e) =>
                            handleClick(e.target.value, MobileData)
                          }
                          value={0}
                        >
                          All
                        </li>
                        <li
                          value={1}
                          className={`${active === 1 ? "active" : ""}`}
                          onClick={(e) =>
                            handleClick(e.target.value, frontendData)
                          }
                        >
                          Web APPs
                        </li>
                        <li
                          className={`${active === 2 ? "active" : ""}`}
                          onClick={(e) =>
                            handleClick(e.target.value, dataBaseData)
                          }
                          value={2}
                        >
                          Mobile Apps
                        </li>
                        <li
                          className={`${active === 3 ? "active" : ""}`}
                          onClick={(e) =>
                            handleClick(e.target.value, dataBaseData)
                          }
                          value={3}
                        >
                          Blockchain
                        </li>
                        <li
                          className={`${active === 4 ? "active" : ""}`}
                          onClick={(e) =>
                            handleClick(e.target.value, dataBaseData)
                          }
                          value={4}
                        >
                          Games
                        </li>
                        <li
                          className={`${active === 5 ? "active" : ""}`}
                          onClick={(e) =>
                            handleClick(e.target.value, dataBaseData)
                          }
                          value={5}
                        >
                          Graphic Designing
                        </li>
                        <li
                          className={`${active === 6 ? "active" : ""}`}
                          onClick={(e) =>
                            handleClick(e.target.value, dataBaseData)
                          }
                          value={6}
                        >
                          2D & 3D Videos
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* ALL IMAGES */}
                <div
                  className={`col-lg-9 col-md-12 col-sm-9 col-xsm-12 ${
                    active == 0 ? "" : "d-none"
                  }`}
                >
                  <div className="tabbing-img">
                    <div className="row gx-0">
                      {allData.map((item, index) => {
                        return (
                          <>
                            {!isMore ? (
                              <>
                                {index <= 5 && (
                                  <div className="col-md-4 m-0 p-0">
                                    <Link to={item.url}>
                                      <div className={`tabbing-card`}>
                                        <div className="content">
                                          <h3>{item.title}</h3>
                                          <Button className="custom-btn">
                                            View Case
                                          </Button>
                                        </div>
                                        <img
                                          src={item.img}
                                          alt="portfolio-images"
                                          className="w-100"
                                        />
                                      </div>
                                    </Link>
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                {" "}
                                <div className="col-md-4 m-0 p-0">
                                  <Link to={item.url}>
                                    <div className={`tabbing-card`}>
                                      <div className="content">
                                        <h3>{item.title}</h3>
                                        <button className="custom-btn ">
                                          View Case
                                        </button>
                                      </div>
                                      <img
                                        src={item.img}
                                        alt="portfolio-images"
                                        className="w-100"
                                      />
                                    </div>
                                  </Link>
                                </div>
                              </>
                            )}
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div className="more-work-btn d-flex">
                    <button
                      className="btn btn-primary "
                      onClick={() => {
                        isMore ? setIsMore(false) : setIsMore(true);
                      }}
                    >
                      <a>{isMore ? "Less Work" : "More Work"}</a>
                    </button>
                  </div>
                </div>
                {/* WEB APPS */}
                <div
                  className={`col-lg-9 col-md-12 col-sm-9 col-xsm-12 ${
                    active == 1 ? "" : "d-none"
                  }`}
                >
                  <div className="tabbing-img">
                    <div className="row gx-0">
                      {allData
                        .filter((dataitem) => dataitem.category == "web app")
                        .map((item, index) => {
                          return (
                            <>
                              {!isMore ? (
                                <>
                                  {index <= 5 && (
                                    <div className="col-md-4 m-0 p-0">
                                      <Link to={item.url}>
                                        <div className={`tabbing-card`}>
                                          <div className="content">
                                            <h3>{item.title}</h3>
                                            <button className="custom-btn ">
                                              View Case
                                            </button>
                                          </div>
                                          <img
                                            src={item.img}
                                            alt="portfolio-images"
                                            className="w-100"
                                          />
                                        </div>
                                      </Link>
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>
                                  <div className="col-md-4 m-0 p-0">
                                    <Link to={item.url}>
                                      <div className={`tabbing-card`}>
                                        <div className="content">
                                          <h3>{item.title}</h3>
                                          <button className="custom-btn ">
                                            View Case
                                          </button>
                                        </div>
                                        <img
                                          src={item.img}
                                          alt="portfolio-images"
                                          className="w-100"
                                        />
                                      </div>
                                    </Link>
                                  </div>
                                </>
                              )}
                            </>
                          );
                        })}
                    </div>
                  </div>
                  <div className="more-work-btn d-flex">
                    <button
                      className="btn btn-primary "
                      onClick={() => {
                        isMore ? setIsMore(false) : setIsMore(true);
                      }}
                    >
                      <a>{isMore ? "Less Work" : "More Work"}</a>
                    </button>
                  </div>
                </div>
                {/* MOBILE APPS */}
                <div
                  className={`col-lg-9 col-md-12 col-sm-9 col-xsm-12 ${
                    active == 2 ? "" : "d-none"
                  }`}
                >
                  <div className="tabbing-img">
                    <div className="row gx-0">
                      {allData
                        .filter((dataitem) => dataitem.category == "mobile app")
                        .map((item, index) => {
                          return (
                            <>
                              {!isMore ? (
                                <>
                                  {index <= 5 && (
                                    <div className="col-md-4 m-0 p-0">
                                      <div className={`tabbing-card`}>
                                        <div className="content">
                                          <h3>{item.title}</h3>
                                          <Link to={item.url}>
                                            <button className="custom-btn ">
                                              View Case
                                            </button>
                                          </Link>
                                        </div>
                                        <img
                                          src={item.img}
                                          alt="portfolio-images"
                                          className="w-100"
                                        />
                                      </div>
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>
                                  <div className="col-md-4 m-0 p-0">
                                    <div className={`tabbing-card`}>
                                      <div className="content">
                                        <h3>{item.title}</h3>
                                        <Link to={item.url}>
                                          <button className="custom-btn ">
                                            View Case
                                          </button>
                                        </Link>
                                      </div>
                                      <img
                                        src={item.img}
                                        alt="portfolio-images"
                                        className="w-100"
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          );
                        })}
                    </div>
                  </div>
                  <div className="more-work-btn d-flex">
                    <button
                      className="btn btn-primary "
                      onClick={() => {
                        isMore ? setIsMore(false) : setIsMore(true);
                      }}
                    >
                      <a>{isMore ? "Less Work" : "More Work"}</a>
                    </button>
                  </div>
                </div>
                {/* BLOCKCHAIN */}
                <div
                  className={`col-lg-9 col-md-12 col-sm-9 col-xsm-12 ${
                    active == 3 ? "" : "d-none"
                  }`}
                >
                  <div className="tabbing-img">
                    <div className="row gx-0">
                      {allData
                        .filter((dataitem) => dataitem.category == "blockchain")
                        .map((item, index) => {
                          return (
                            <>
                              {!isMore ? (
                                <>
                                  {index <= 5 && (
                                    <div className="col-md-4 m-0 p-0">
                                      <Link to={item.url}>
                                        <div className={`tabbing-card`}>
                                          <div className="content">
                                            <h3>{item.title}</h3>
                                            <button className="custom-btn ">
                                              View Case
                                            </button>
                                          </div>
                                          <img
                                            src={item.img}
                                            alt="portfolio-images"
                                            className="w-100"
                                          />
                                        </div>
                                      </Link>
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>
                                  <div className="col-md-4 m-0 p-0">
                                    <Link to={item.url}>
                                      <div className={`tabbing-card`}>
                                        <div className="content">
                                          <h3>{item.title}</h3>
                                          <button className="custom-btn ">
                                            View Case
                                          </button>
                                        </div>
                                        <img
                                          src={item.img}
                                          alt="portfolio-images"
                                          className="w-100"
                                        />
                                      </div>
                                    </Link>
                                  </div>
                                </>
                              )}
                            </>
                          );
                        })}
                    </div>
                  </div>
                  <div className="more-work-btn d-flex">
                    <button
                      className="btn btn-primary "
                      onClick={() => {
                        isMore ? setIsMore(false) : setIsMore(true);
                      }}
                    >
                      <a>{isMore ? "Less Work" : "More Work"}</a>
                    </button>
                  </div>
                </div>
                {/* GAMES */}
                <div
                  className={`col-lg-9 col-md-12 col-sm-9 col-xsm-12 ${
                    active == 4 ? "" : "d-none"
                  }`}
                >
                  <div className="tabbing-img">
                    <div className="row gx-0">
                      {allData
                        .filter((dataitem) => dataitem.category == "game")
                        .map((item, index) => {
                          return (
                            <>
                              {!isMore ? (
                                <>
                                  {index <= 5 && (
                                    <div className="col-md-4 m-0 p-0">
                                      <Link to={item.url}>
                                        <div className={`tabbing-card`}>
                                          <div className="content">
                                            <h3>{item.title}</h3>
                                            <button className="custom-btn ">
                                              View Case
                                            </button>
                                          </div>
                                          <img
                                            src={item.img}
                                            alt="portfolio-images"
                                            className="w-100"
                                          />
                                        </div>
                                      </Link>
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>
                                  <div className="col-md-4 m-0 p-0">
                                    <Link to={item.url}>
                                      <div className={`tabbing-card`}>
                                        <div className="content">
                                          <h3>{item.title}</h3>
                                          <button className="custom-btn ">
                                            View Case
                                          </button>
                                        </div>
                                        <img
                                          src={item.img}
                                          alt="portfolio-images"
                                          className="w-100"
                                        />
                                      </div>
                                    </Link>
                                  </div>
                                </>
                              )}
                            </>
                          );
                        })}
                    </div>
                  </div>
                  <div className="more-work-btn d-flex">
                    <button
                      className="btn btn-primary "
                      onClick={() => {
                        isMore ? setIsMore(false) : setIsMore(true);
                      }}
                    >
                      <a>{isMore ? "Less Work" : "More Work"}</a>
                    </button>
                  </div>
                </div>
                {/* GRAPHIC DESIGNING */}
                <div
                  className={`col-lg-9 col-md-12 col-sm-9 col-xsm-12 ${
                    active == 5 ? "" : "d-none"
                  }`}
                >
                  <div className="tabbing-img">
                    <div className="row gx-0">
                      {allData
                        .filter(
                          (dataitem) => dataitem.category == "graphic design"
                        )
                        .map((item, index) => {
                          return (
                            <>
                              {!isMore ? (
                                <>
                                  {index <= 5 && (
                                    <div className="col-md-4 m-0 p-0">
                                      <Link to={item.url}>
                                        <div className={`tabbing-card`}>
                                          <div className="content">
                                            <h3>{item.title}</h3>
                                            <button className="custom-btn ">
                                              View Case
                                            </button>
                                          </div>
                                          <img
                                            src={item.img}
                                            alt="portfolio-images"
                                            className="w-100"
                                          />
                                        </div>
                                      </Link>
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>
                                  <div className="col-md-4 m-0 p-0">
                                    <Link to={item.url}>
                                      <div className={`tabbing-card`}>
                                        <div className="content">
                                          <h3>{item.title}</h3>
                                          <button className="custom-btn ">
                                            View Case
                                          </button>
                                        </div>
                                        <img
                                          src={item.img}
                                          alt="portfolio-images"
                                          className="w-100"
                                        />
                                      </div>
                                    </Link>
                                  </div>
                                </>
                              )}
                            </>
                          );
                        })}
                    </div>
                  </div>
                  <div className="more-work-btn d-flex">
                    <button
                      className="btn btn-primary "
                      onClick={() => {
                        isMore ? setIsMore(false) : setIsMore(true);
                      }}
                    >
                      <a>{isMore ? "Less Work" : "More Work"}</a>
                    </button>
                  </div>
                </div>
                {/* 2D & 3D Videos*/}
                <div
                  className={`col-lg-9 col-md-12 col-sm-9 col-xsm-12 ${
                    active == 6 ? "" : "d-none"
                  }`}
                >
                  <div className="tabbing-img">
                    <div className="row gx-0">
                      {allData
                        .filter((dataitem) => dataitem.category == "video")
                        .map((item, index) => {
                          return (
                            <>
                              {!isMore ? (
                                <>
                                  {index <= 5 && (
                                    <div className="col-md-4 m-0 p-0">
                                      <Link to={item.url}>
                                        <div className={`tabbing-card`}>
                                          <div className="content">
                                            <h3>{item.title}</h3>
                                            <button className="custom-btn ">
                                              View Case
                                            </button>
                                          </div>
                                          <img
                                            src={item.img}
                                            alt="portfolio-images"
                                            className="w-100"
                                          />
                                        </div>
                                      </Link>
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>
                                  <div className="col-md-4 m-0 p-0">
                                    <Link to={item.url}>
                                      <div className={`tabbing-card`}>
                                        <div className="content">
                                          <h3>{item.title}</h3>
                                          <button className="custom-btn">
                                            View Case
                                          </button>
                                        </div>
                                        <img
                                          src={item.img}
                                          alt="portfolio-images"
                                          className="w-100"
                                        />
                                      </div>
                                    </Link>
                                  </div>
                                </>
                              )}
                            </>
                          );
                        })}
                    </div>
                  </div>
                  <div className="more-work-btn d-flex">
                    <button
                      className="btn btn-primary "
                      onClick={() => {
                        isMore ? setIsMore(false) : setIsMore(true);
                      }}
                    >
                      <a>{isMore ? "Less Work" : "More Work"}</a>
                    </button>
                  </div>
                </div>
                {/* <div className="col-md-9 d-flex align-items-sm-end">
                  <div className="tech-icons-main">
                    <div className="row">
                      <div className="col-md-12 pb-3">
                        <div className="main-technical-icons">
                          <div className="row">
                            {firstData.map((item, ind) => (
                              <div className="col-3 col-md-3 pb-3" key={ind}>
                                <Bounce top>
                                  <div className="tech-icons wow fadeInBottomLeft">
                                    <img src={imageUrl(item.imageUrl)} alt="" />
                                    <h4>{item.title}</h4>
                                  </div>
                                </Bounce>
                              </div>
                            ))}
                            {secondData.map((item, ind) => (
                              <div
                                className={`col-4 col-md-4 ${
                                  ind === 0
                                    ? " d-flex justify-content-end"
                                    : ind === 2
                                    ? " d-flex justify-content-start"
                                    : ""
                                }`}
                                key={ind}
                              >
                                <Bounce bottom>
                                  <div className="tech-icons">
                                    <img
                                      src={imageUrl(item.imageUrl)}
                                      alt="ionic-icon.png"
                                    />
                                    <h4>{item.title}</h4>
                                  </div>
                                </Bounce>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeTechnologies;
