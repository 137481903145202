import React, { useRef, useState } from "react";
import ReCAPTCHA from 'react-google-recaptcha';
import { Footer, Header } from "../../components";
import { CareerBanner } from "../career/Career";
import { Parallax } from "react-scroll-parallax";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core/styles";
import "./contact-us.scss";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// const useStyles = makeStyles({
//   input: {
//     color: "black",
//   },
// });


function ContactUs() {
  const [ captcha, setCaptcha ] = useState();

  const handleCaptcha = (value) => {
    setCaptcha(value);
  }  

  //   const para = (
  //     <h6 className="para">
  //       We're a <span className="color-primary"> group</span> of inventive
  //       masterminds who have assembled a
  //       <span className="color-primary"> business </span>to change
  //       <span className="color-primary"> Pakistan </span> and the world.
  //     </h6>
  //   );
  const [submitBtn, setSubmitBtn] = useState(false);
  const form = useRef();

  const formSubmitHandler = (e) => {
    e.preventDefault();
    setSubmitBtn(!submitBtn);
    emailjs
      .sendForm(
        "service_e9b9pgq",
        "contact_form",
        form.current,
        "EmOv5QvdQDIqn2z4O"
      )
      .then(
        (result) => {
          console.log(result.text);
          // form.reset();
          toast.success("sucessfully submitted!", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setSubmitBtn(!submitBtn);
        },
        (error) => {
          toast.error(`${error.text}`, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          console.log(error.text);
        }
      );
    form.current.reset();
  };


  return (
    <>
      <div id="about" className="for-parallax-footer">
        {/* =========================banner============ */}
        <section id="banner">
          <CareerBanner page="about" title={"Contact Us"} />
        </section>
        <section id="about-content">
          <div className="container">
            <div className="sec-padding">
              <div className="main-contact-us">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form">
                      <form
                        ref={form}
                        onSubmit={formSubmitHandler}
                        className="d-flex"
                      >
                        <div className="main-form">
                          <div className="inner-from">
                            <div className="heading">
                              <h1>Request for proposal</h1>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="fields">
                                  {" "}
                                  <TextField
                                    name="user_name"
                                    id="user_name"
                                    label="Name"
                                    variant="standard"
                                    type={"text"}
                                    required
                                    sx={{
                                      m: 1,
                                      width: "500px",
                                      maxWidth: "100%",
                                    }}
                                  />
                                </div>
                                <div className="fields">
                                  {" "}
                                  <TextField
                                    id="email"
                                    type={"email"}
                                    label="Email"
                                    variant="standard"
                                    inputProps={{
                                      pattern:
                                        "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$",
                                    }}
                                    name="user_email"
                                    required
                                    sx={{
                                      m: 1,
                                      width: "500px",
                                      maxWidth: "100%",
                                    }}
                                  />
                                </div>
                                <div className="fields">
                                  {" "}
                                  <TextField
                                    id="phone"
                                    label="Phone"
                                    type={"text"}
                                    inputProps={{
                                      pattern: "[0-9]{11}",
                                    }}
                                    placeholder="eg: 03000000000"
                                    variant="standard"
                                    name="user_mobile"
                                    required
                                    sx={{
                                      m: 1,
                                      width: "500px",
                                      maxWidth: "100%",
                                    }}
                                  />
                                </div>
                                <div className="fields">
                                  {" "}
                                  <TextField
                                    id="purpose"
                                    label="Purpose"
                                    variant="standard"
                                    name="user_message"
                                    required
                                    sx={{
                                      m: 1,
                                      width: "500px",
                                      maxWidth: "100%",
                                    }}
                                    noValidate
                                    autoComplete="off"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <textarea
                                  name="message_description"
                                  id=""
                                  cols="30"
                                  rows="10"
                                  placeholder="Your message"
                                ></textarea>
                              </div>
                              <div className="col-md-12">
                                <div className="w-100">
                                <ReCAPTCHA
                                  sitekey= {'6LdemfAiAAAAAFkD0yiFgGwkQXZ6b0w5sY_IwEpy'}
                                  onChange={handleCaptcha}
                                />
                                </div>
                                <div className="button submit-btn">
                                  <button
                                    className="custom-btn"
                                    type="submit"
                                    disabled={submitBtn}
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* ==================toast-container================== */}
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* ======================end===================== */}
      {/* <Footer /> */}
      <Parallax translateY={[-25, 40]}>
        <Footer />
      </Parallax>
    </>
  );
}

export default ContactUs;

